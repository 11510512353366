import { ReactElement } from "react";
import { useCurrencyApi } from "../shared/currencyApi";
import { Trending } from "../types/Crypto";

export default function News(): ReactElement {

  // Fetchs top 7 trending coins data via API 
  const [trend] = useCurrencyApi<Trending>('search/trending');

  if (!trend) {
    return <p>Lade</p>
  }
 
  return (
    <><section className="hero">
      <div className="hero-body">
        <p className="title">
          Top 7 Trending Coins
           more blogs here <a href="https://gptblog.online/">Blogs</a>
        </p>
        <p className="subtitle">
          "No News is good News"
        </p>
      </div>
    </section>
    <div className="content is-normal">
      <ol>{(trend.coins.map((key, index) => (
        <li key={index}><img key={key.item.coin_id} src={key.item.large} width={40}></img>{key.item.name}</li>
      )))}
      </ol>
    </div>
    </>
  )
}
