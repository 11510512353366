
import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import { useCurrencyApi } from "../shared/currencyApi";
import { CoinDetails } from "../types/Crypto";

export default function MoreDetails(): ReactElement {

  //Takes id from Link
  const { id } = useParams<{ id: string }>()
  
  //Changes id to lowercase to match API link
  const name = id?.toLocaleLowerCase();

  //API Call with name ID
  const [coins] = useCurrencyApi<CoinDetails>(`coins/${name}?vs_currency=usd&order=market_cap_desc&per_page=10&page=1&sparkline=false`);

  if (!coins) {
    return <p>Lade</p>
  }

  //Filters keys and values and pushes into an Array
  function filterObjectToArray(coins: object) {
    const newData = []
    for (const [key, value] of Object.entries(coins)) {
      if (key.includes(key) && typeof value != "object") {
        const newValues = `${key}: ${value}`;
        newData.push(newValues);
      }
    }
    return newData;
  }

  //Calls function into variable.
  const newDetails = filterObjectToArray(coins);
 
  //Returns HTML with maped keys
  return (
    <div className="content is-medium">
      <h1>More Details</h1>
      <div className="box">{coins.description.en.slice(0, 650).replace('urlRegex', '')}....</div>
      <ol>{(newDetails.map((newDetails, index) => (
        <li key={index}>{newDetails}</li>
      )))}
      </ol>
      <button className="button is-info">
        <Link className="button is-info" to={`/coinsearch/`}>Go back</Link>
      </button>
    </div>
  )
}