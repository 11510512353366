import { ReactElement } from "react";

export default function Contact(): ReactElement {
  return (
    <>
      <section className="hero">
        <div className="hero-body">
          <p className="title">
      Contact Form
          </p>
          <p className="subtitle">
          “It's very, very dangerous to lose contact with living nature.”
          ― Albert Hofmann
          </p>
        </div>
      </section>
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">From</label>
        </div>
        <div className="field-body">
          <div className="field">
            <p className="control is-expanded has-icons-left">
              <input className="input" type="text" placeholder="Name"></input>
              <span className="icon is-small is-left">
                <i className="fas fa-user"></i>
              </span>
            </p>
          </div>
          <div className="field">
            <p className="control is-expanded has-icons-left has-icons-right">
              <input className="input is-success" type="email" placeholder="Email"></input>
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="icon is-small is-right">
                <i className="fas fa-check"></i>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label"></div>
        <div className="field-body">
          <div className="field is-expanded">
            <div className="field has-addons">
              <p className="control">
                <a className="button is-static">
                                    +44
                </a>
              </p>
              <p className="control is-expanded">
                <input className="input" type="tel" placeholder="Your phone number"></input>
              </p>
            </div>
            <p className="help">Do not enter the first zero</p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Department</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <div className="select is-fullwidth">
                <select>
                  <option>Business development</option>
                  <option>Marketing</option>
                  <option>Sales</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <label className="label">Already a member?</label>
        </div>
        <div className="field-body">
          <div className="field is-narrow">
            <div className="control">
              <label className="radio">
                <input type="radio" name="member"></input>
                                Yes
              </label>
              <label className="radio">
                <input type="radio" name="member"></input>
                                No
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Subject</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <input className="input is-danger" type="text" placeholder="e.g. Partnership opportunity"></input>
            </div>
            <p className="help is-danger">
                            This field is required
            </p>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Question</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <textarea className="textarea" placeholder="Explain how we can help you"></textarea>
            </div>
          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">

        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <button className="button is-primary">
                                Send message
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="field has-addons has-addons-centre">
        <p className="control">
          <span className="select">
            <select>
              <option>Bitcoin</option>
              <option>Cardano</option>
              <option>XRP</option>
            </select>
          </span>
        </p>
        <p className="control">
          <input className="input" type="text" placeholder="Amount of money"></input>
        </p>
        <p className="control">
          <a className="button is-primary">
                        Transfer
          </a>
        </p>
      </div>
    </>
  )
}