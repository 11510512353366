import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
// import reportWebVitals from './reportWebVitals';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
//Renders the Dom App.tsx
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);