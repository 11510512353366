import { ReactElement, SetStateAction, useState } from "react";
import { useCurrencyApi } from "../shared/currencyApi";
import { Coin } from "../types/Crypto";
import { Coins } from './Coins'

export default function CoinSearch(): ReactElement {

  //handle Change on search value
  const handleChange = (e: { target: { value: SetStateAction<string>; }; }) => {
    setSearch(e.target.value);
  };

  //API Call back with added Interface
  const [coins] = useCurrencyApi<Coin[]>("coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false");

  //Set Search useState setState
  const [search, setSearch] = useState('');

  //Filtered Search works with UseState
  const filteredCoins = coins?.filter(coins =>
    coins.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <><a className="navbar-item">

    </a><div className='panel'>
      <p className="panel-heading">
          Search a coin...
      </p>
      <div className='panel-block'>
        <p className="control has-icons-left">
          <input
            className='input'
            type='text'
            onChange={handleChange}
            placeholder='Search' />
          <span className="icon is-left">
            <i className="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>
      {filteredCoins?.map(coins => {
        return (
          <Coins
            key={coins?.id}
            id={coins.id}
            name={coins.name}
            price={coins.current_price}
            symbol={coins.symbol}
            marketcap={coins.total_volume}
            volume={coins.market_cap}
            image={coins.image}
            priceChange={coins.price_change_percentage_24h}
            maxSupply={coins.max_supply}
            inCirculation={coins.circulating_supply} />
        );
      })}
    </div></>
  );
}