import { ReactElement } from "react";

export default function Home(): ReactElement{
  return(
   <><section className="hero">
          <div className="hero-body">
              <p className="title">
                  Coin Project
              </p>
              <p className="subtitle">
                  "The blockchain is an incorruptible digital ledger of economic transactions"
              </p>
          </div>
      </section><div className="notification is-primary">
              <div className="content is-normal">
                  <h1>Hello and welcome to my React API coin project</h1>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla accumsan, metus ultrices eleifend gravida, nulla nunc varius lectus, nec rutrum justo nibh eu lectus. Ut vulputate semper dui. Fusce erat odio, sollicitudin vel erat vel, interdum mattis neque.</p>
                  <h2>About me</h2>
                  <p>Hi my name is Phil the <strong>great</strong> bandit.This week I am currently deploying React Projects</p>
                  <ul>
                      <li>React</li>
                      <li>TypeScript</li>
                      <li>NODE.JS</li>
                      <li>PHP</li>
                      <li>HTML5</li>
                      <li>Mongodb</li>
                  </ul>
              </div>
          </div><footer className="footer">
              <div className="content has-text-centered">
                  is licensed by Phil.
              </div>
          </footer></>
  )
}
