import { ReactElement } from 'react';
import { Navigate, Route, Routes as RRDRoutes } from 'react-router-dom';
import CoinSearch from './CoinSearch';
import Contact from './Contact';
import Home from './Home';
import News from './News';
import MoreDetails from './MoreDetails';

//All routes and paths to pages
function Routes(): ReactElement {
  return (
    <RRDRoutes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/coinsearch" element={<CoinSearch />} />
      <Route path="/coinsearch/moredetails/:id" element={<MoreDetails />} />
      <Route path="/news" element={<News />} />
      <Route path="/contact" element={<Contact />} />
    </RRDRoutes>
  )
}
export default Routes;