import React from 'react';
//Imports Chart Data from Recharts
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { Link } from "react-router-dom";

//All elements for dom use
export const Coins = ({ id, name, price, symbol, marketcap, volume, image, priceChange, maxSupply, inCirculation }) => {

  //Data for reCharts
  const data = [{ name: name, price: price, marketcap: marketcap, volume: volume }];
  
  // Renders all displayed data on search using Bulma CSS and Recharts
  return (
    <><div className="tile is-ancestor">
      <div className="tile is-vertical is-8">
        <div className="tile">
          <div className="tile is-parent is-vertical">
            <article className="tile is-child notification is-primary">

              <p className="title"> <img src={image} width={40} height={40} />  {name}</p>
              <p className="subtitle"></p>
              <p className="subtitle">{symbol}</p>
              <button className="button is-info">
                <Link className="button is-info" to={`/coinsearch/moredetails/${id}`}>More Details</Link>
              </button>
            </article>
            <article className="tile is-child notification is-warning">
              <p className="title">Price...</p>
              <p className="title">${price}</p>
              <p className="subtitle"></p>
            </article>
          </div>
          <div className="tile is-parent">
            <article className="tile is-child notification is-info" fill="#9884d8">
              <p className="title">Charts</p>
              <p className="subtitle">Chart {name}</p>

              <BarChart width={300} height={300} data={data}>
                <XAxis dataKey="name" stroke="#ffffff" />
                <YAxis stroke="#ffffff" />
                <Tooltip stroke="#00cc0c" />
                <CartesianGrid stroke="#00cc0c" strokeDasharray="5 5" />
                <Bar isAnimationActive={true} dataKey="price" fill="#ff1100" barSize={30} />
              </BarChart>

            </article>
          </div>
        </div>
        <div className="tile is-parent">
          <article className="tile is-child notification is-danger">
            <p className="title">Price Change</p>
            <p className="subtitle">Today its going wild!</p>
            <div className="content">
              {priceChange < 0 ? (
                <p className="title">{priceChange.toFixed(2)}%</p>
              ) : (
                <p className="title">{priceChange.toFixed(2)}%</p>
              )}
            </div>
            <h2>In Supply to Max Circulation</h2>
            <progress className="progress is-success" value={inCirculation} max={maxSupply}>{inCirculation}</progress>
          </article>
        </div>
      </div>
      <div className="tile is-parent">
        <article className="tile is-child notification is-success">
          <div className="content">
            <p className="title">Extra Info</p>
            <p className="subtitle">With even more content</p>
            <div className="content">
              <p className="subtitle"> Volume: ${marketcap.toLocaleString()}</p>
              <p className="subtitle">  Marketcap: ${volume.toLocaleString()}</p>
            </div>
          </div>
        </article>
      </div>
    </div><section className="section">
      <h1 className="title">Section</h1>
      <h2 className="subtitle">
          A simple container to divide your page into <strong>sections</strong>, like the one you're currently reading.
      </h2>
    </section></>
  );
};
